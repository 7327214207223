<template>
  <div class="hello">
    <h2>{{ msg }}</h2>
    <p>
      <a href="https://www.instagram.com/susampad1?igsh=YjFlcDN2czlzOGtx." target="_blank" rel="noopener">
        I’d really appreciate your support by giving my Instagram page </a>.
    </p>
    <p>📞+91 9441238332 | + 91 8500516706 </p>
    <p> 🌿 Fresh Fruits | Organic Products | Honey | Ghee | Dry Fruits | Cold Pressed Oils 🍯</p>
    <p>🍃 Available Online & Offline</p>
    <p>🌱 Bringing Nature’s Best to You</p>
    <p>I am planning to start a new business where we offer fresh fruits,honey, ghee, dry fruits,cold-pressed oils and organic products both online and offline.</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
